<template>
  <base-selector-autocomplete
    :items="items"
    :initial-value="initialValue"
    item-text="full_name"
    item-value="_id"
    label="Select workers"
    @select="newSelect => $emit('select', newSelect, 'workers')"
  />
</template>

<script>
  import BaseSelectorAutocomplete from '@/components/base/selectors/BaseSelectorAutocomplete';
  import { mapGetters } from 'vuex';

  export default {
    name: 'BaseWorkersSelector',
    components: {
      BaseSelectorAutocomplete,
    },
    props: {
      initialValue: {
        type: Object,
        default: () => {},
      },
    },
    computed: {
      ...mapGetters({
        workers: 'workers/workersFilter',
      }),
      items () {
        const items = [{
          full_name: 'No workers',
          _id: '',
        }];
        this.workers.map(worker => {
          items.push({
            full_name: `${worker.device_name} ${worker.instance_number} ${worker._id}`,
            _id: worker._id,
          });
        });
        return items;
      },
    },
  };
</script>

<style scoped>

</style>
